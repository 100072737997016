@font-face {
  font-family: "Establishment";
  src: local("Establishment"),
    url(../fonts/Establishment.ttf) format("truetype");
}

#root > div > svg:first-of-type {
  display: none;
}
html {
  font-size: 16px;
}
body {
  min-height: 100vh !important;
}
.main_container {
  min-height: 100vh;
}
:root {
  --color-white: #ffffff;

  --color-gray-light: #f3f3f3;
  --color-gray-lighter: #eaecf2;
  --color-gray-shade: #f2f7fc;
  --color-gray-dark: #9e9e9e;
  --color-gray-darker: #8898aa;

  --color-blue-dark: #32325d;
  --color-blue-light: #c9cee0;
  --color-blue-sky: #f2f7fc;
  --color-blue-sky-dark: #ddeaff;
  --color-blue-med: #4f52b2;

  --color-black-shade-dark: #252525;
  --color-black-shade: #1d1d1d;
  --color-black: #000000;

  --color-yellow-dark: #eeec30;

  --color-orange-mid: #f26a5b;

  --color-pink-light: #ffedef;
  --color-red-light: #f26a5b;

  --color-purple: #4f52b2;
  --color-gray-shade-2: #a8a8a84d;
  --color-gray-shade-1: #b2bbcb;
  --color-gray: #7e8fa0;
}
.default {
  --title-color: var(--color-blue-dark);
  --border-color: var(--color-blue-light);
  --progress-bar-color: var(--color-orange-mid);

  --course-card-overlay: var(--color-gray-shade-2);
  --course-card-btn-color: var(--color-orange-mid);
  --course-card-btn-text-color: var(--color-white);
  --course-card-btn-border-color: var(--color-orange-mid);

  --course-card-btn-hover-color: var(--color-orange-mid);
  --course-card-btn-hover-text-color: var(--color-white);
  --course-card-btn-hover-border-color: var(--color-orange-mid);

  --background-color: var(--color-gray-light);
  --progress-bar-bg: var(--color-gray-shade);

  --card-bg: var(--color-white);
  --card-title: var(--color-blue-dark);

  --lia-container-bg: var(--color-blue-sky);

  --step-icon-color: var(--color-gray-darker);
  --reminder-icon-color: var(--color-blue-dark);

  --reminder-card-selected-color: var(--color-red-light);
  --reminder-card-selected-bg: var(--color-pink-light);

  --card-sub-title: var(--color-gray-darker);
  --font-color: var(--color-black);

  --filters-bg-color: var(--color-white);
  --filters-font-color: var(--color-blue-dark);
  --filters-border-color: var(--color-white);

  --sub-title-color: var(--color-orange-mid);
  --filter-search-bg: var(--color-gray-shade);
  --document-list-item-title: var(--color-gray);
}
.dark {
  --title-color: var(--color-white);
  --border-color: var(--color-blue-light);
  --progress-bar-color: var(--color-white);

  --course-card-btn-color: var(--color-blue-med);
  --course-card-btn-text-color: var(--color-white);
  --course-card-btn-border-color: var(--color-blue-med);

  --course-card-btn-hover-color: var(--color-blue-med);
  --course-card-btn-hover-text-color: var(--color-white);
  --course-card-btn-hover-border-color: var(--color-blue-med);

  --background-color: var(--color-black-shade);
  --progress-bar-bg: var(--color-gray-dark);

  --card-bg: var(--color-black-shade-dark);
  --card-title: var(--color-white);

  --step-icon-color: var(--color-white);
  --reminder-icon-color: var(--color-white);

  --lia-container-bg: var(--color-black-shade-dark);

  --reminder-card-selected-color: var(--color-red-light);
  --reminder-card-selected-bg: var(--color-pink-light);
  --card-sub-title: var(--color-white);
  --font-color: var(--color-white);

  --filters-bg-color: var(--color-black);
  --filters-font-color: var(--color-white);
  --filters-border-color: var(--color-black);

  --sub-title-color: var(--color-orange-mid);

  --filter-search-bg: var(--color-black);
  --document-list-item-title: var(--color-gray);
}
.contrast {
  --title-color: var(--color-white);
  --border-color: var(--color-blue-light);
  --progress-bar-color: var(--color-white);

  --course-card-btn-color: var(--color-black);
  --course-card-btn-text-color: var(--color-white);
  --course-card-btn-border-color: var(--color-white);

  --course-card-btn-hover-color: var(--color-yellow-dark);
  --course-card-btn-hover-text-color: var(--color-black);
  --course-card-btn-hover-border-color: var(--color-yellow-dark);

  --background-color: var(--color-black);
  --progress-bar-bg: var(--color-gray-dark);

  --card-bg: var(--color-black-shade-dark);
  --card-title: var(--color-yellow-dark);

  --step-icon-color: var(--color-yellow-dark);
  --reminder-icon-color: var(--color-yellow-dark);

  --lia-container-bg: var(--color-black);

  --reminder-card-selected-color: var(--color-red-light);
  --reminder-card-selected-bg: var(--color-pink-light);
  --card-sub-title: var(--color-white);
  --font-color: var(--color-white);

  --filters-bg-color: var(--color-black);
  --filters-font-color: var(--color-white);
  --filters-border-color: var(--color-white);

  --sub-title-color: var(--color-yellow-dark);

  --filter-search-bg: var(--color-black);
  --document-list-item-title: var(--color-gray);
}
.react-joyride__tooltip > div > div {
  padding: 0 !important;
}
