.smallTextRegular {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--color-orange-mid);
}

.regularTextSemiBold {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--color-black);
}

.learningPathCardContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  padding: 4px;
  background-color: white;
  border-radius: 8px;
  z-index: 2;
}

.learningPathInnerCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.learningPathImage {
  max-height: 42px;
  max-width: 42px;
  border-radius: 8px !important;
}

.learningPathTitle {
  margin-left: 8px;
  flex-direction: column;
  display: flex;
}
